<template>
    <div class="scores">
      <div class="score-box">
        <h1>Latest Scores</h1>
        <div class="scores_container">
          <Score v-for="item in latestScoreData"
            v-bind:scoreItem=item
            v-bind:key=item.run_id
          ></Score>
        </div>
      </div>
      <div class="score-box">
        <h1>All Time Scores</h1>
        <div class="scores_container">
          <Score v-for="item in allTimeScoreData"
            v-bind:scoreItem=item
            v-bind:key=item.run_id
          ></Score>
        </div>
      </div>
    </div>
</template>


<script>
// @ is an alias to /src
import Score from '@/components/Score.vue'

export default {
  components: {
    Score
  },
  data() {
    return {
      latestScoreData: [],
      allTimeScoreData: []
    }
  },
  mounted() {
    this.fetchScoreData('latest');
    // this.fetchScoreData('alltime');
  },
  methods: {
    fetchScoreData(path) {
      fetch('https://api.dev.cloudbuilderchallenge.com/'+path)
        .then(response => response.json())
        .then(json => {
          this.latestScoreData = json;
        })
        .catch(error => console.error('Error:', error));
    }
  }
}
</script>

<style scoped>

.scores {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.score-box {
  width: 35em;
}

</style>