<template>
  <div class="score_item">
    <span class="name"><a :href="scoreItem.user_uri">{{ scoreItem.user_name }}</a></span>
    <span class="score">{{ padded_score }}</span>
  </div>
</template>

<script>
export default {
  name: 'Score',
  props: {
    scoreItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    padded_score() {
      return this.scoreItem.score.toString().padStart(10, '0');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.name {
  display: inline;
  text-align: left;
}

.score {
  display: inline;
  text-align: right;
}

.score_item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6em;
}

</style>
